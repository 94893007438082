<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">回收管理</a></li>
            <li class="breadcrumb-item active" id="title3">完成回收</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">完成回收</h4>
      </div>
    </div>
    <el-form :model="formData" label-width="20%" ref="formDataRef" :rules="addrules" align="left">

      <el-form-item label="回收积分" prop="rmoney">
        <el-input v-model="formData.rCarbonPoint" placeholder="回收积分" style="width:50%;"></el-input>
      </el-form-item>
      <!-- 新增 -->
      <el-form-item label="回收碳积分" prop="rCarbonPoint">
        <el-input v-model="formData.rmoney" placeholder="回收碳积分" style="width:50%;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="save" :loading="btnLoading" icon="el-icon-upload">提 交</el-button>
        <el-button type="info" size="small" @click="goBack" icon="el-icon-back">返 回</el-button>
      </el-form-item>
    </el-form>


  </div>
</template>
<script>
import request, { base } from "../../../utils/http";

export default {
  name: 'RecyclesEdit',
  components: {

  },
  data() {
    return {
      id: '',
      isClear: false,
      uploadVisible: false,
      btnLoading: false, //保存按钮加载状态     
      formData: {}, //表单数据     
      addrules: {
        rmoney: [
          { required: true, message: "请输入回收碳积分", trigger: "blur" },
        ],
      }, //表单验证规则      

    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDatas();
  },


  methods: {

    //获取列表数据
    getDatas() {
      let para = {
      };
      this.listLoading = true;
      let url = base + "/recycles/get?id=" + this.id;
      request.post(url, para).then((res) => {
        this.formData = JSON.parse(JSON.stringify(res.resdata));
        this.listLoading = false;


      });
    },

    // 添加
    save() {
      this.$refs["formDataRef"].validate((valid) => { //验证表单
        if (valid) {
          let url = base + "/recycles/update";
          this.btnLoading = true;

          //得到回收积分，金额取整，转换为整数
          this.formData.rpoint =(20/9) * Math.floor(this.formData.rmoney);

          request.post(url, this.formData).then((res) => { //发送请求         
            if (res.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
                offset: 320,
              });
              this.$router.push({
                path: "/RecyclesManage",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "error",
                offset: 320,
              });
            }
            this.btnLoading = false;
          });
        }

      });
    },

    // 返回
    goBack() {
      this.$router.push({
        path: "/RecyclesManage",
      });
    },




  },
}

</script>
<style scoped></style>
 

